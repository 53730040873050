import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
      iconfont: 'mdi',
   },
    theme: { 
      dark: true,
      themes: {
        dark: {
          primary: '#7E57C2',
          secondary: '#045D56',
          anchor: '#ffcf44',
        },
      },
    },
});
