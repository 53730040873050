import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import moment from "moment";

Vue.config.productionTip = false;

//Layouts
import DashboardLayout from '@/layouts/DashboardLayout.vue';
import Default from '@/layouts/Default.vue';

Vue.component('dashboard-layout', DashboardLayout);
Vue.component('default-layout', Default);


import JsonCSV from 'vue-json-csv';

Vue.component('downloadCsv', JsonCSV);

import { ImageEditor } from "@toast-ui/vue-image-editor";

Vue.component('tui-image-editor', ImageEditor)

Vue.filter("without", function(value) {
  if (!value) return "Não informado";
  return value;
});

Vue.filter("date", function(value) {
  if (!value) return "";
  var data = value.toDate();
  return moment(data).format("DD/MM/YYYY");
});

Vue.filter("dateTime", function(value) {
  if (!value) return "";
  var data = value.toDate();
  return moment(data).format("DD/MM/YYYY HH:mm:ss");
});

Vue.filter("more", function(value) {
  if (!value) return "";
  if (value.length > 8) {
      value = value.slice(-value.length, 8);
      return value.padEnd(11, ".");
  }
});

Vue.filter("brl", function(value) {
  if (!value) return "R$ 0,00";
  if (value == 0) return "R$ 0,00";
  const formatter = new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2
  })
  var data = formatter.format(value);
  return data;
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
