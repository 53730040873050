<template>
  <v-app>
    <nav>
      <v-app-bar flat app elevation="4" class="purple darken-2 white--text">
        <v-app-bar-nav-icon class="white--text" dark @click="drawer = !drawer" />
        <v-toolbar-title class="headline">
          <span>Festit </span>
          <span class="font-weight-light">Administrativo</span>
        </v-toolbar-title>
        <v-spacer />
        {{ nome }}
        <v-menu left bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-menu-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item key="exit" @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-exit-to-app</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="body-2" style="font-size: 10px;">
                  Sair
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-app-bar>
      <v-navigation-drawer v-model="drawer" app dark  width="300">
        <v-container class="purple darken-3 pt-0 pb-0">
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="white--text display-1">
                  Festit
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
        <v-list flat>
          <v-list-item-group >
            <v-list-item
              v-for="item in items"
              :key="item.title"
              :disabled="item.disable"
              :to="item.to"
            >
              <v-list-item-icon>
                <v-icon>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title class="body-2" style="font-size: 10px;">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>

          <v-list-group
            v-for="itemGroup in itemsGrouped"
            :key="itemGroup.title"
            :prepend-icon="itemGroup.icon"
            color="white"
            no-action
          >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="body-2" style="font-size: 10px;">{{
                  itemGroup.title
                }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="subItem in itemGroup.items"
              :key="subItem.title"
              :to="subItem.to"
            >
              <v-list-item-content>
                <v-list-item-title
                  v-text="subItem.title"
                  class="body-2"
                  style="font-size: 10px;"
                ></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>

          <v-list-item key="exit" @click="logout()">
            <v-list-item-icon>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title class="body-2" style="font-size: 10px;">
                Sair
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </nav>
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<script>
import database from "@/services/database";

export default {
  data() {
    return {
      loja_url: "",
      drawer: true,
      nome: "Administrador",
      items: [
        {
          title: "Visão Geral",
          icon: "file-chart",
          active: false,
          disable: false,
          to: "/sistema/visao-geral/",
        },
      ],
      itemsGrouped: [
        {
          title: "Fornecedores",
          icon: "mdi-badge-account",
          active: false,
          disable: false,
          items: [
            {
              title: "Pendentes",
              active: false,
              disable: false,
              to: "/sistema/fornecedores/pendentes/",
            },
            {
              title: "Avaliações Pendentes",
              active: false,
              disable: false,
              to: "/sistema/fornecedores/avaliacoes/",
            },  
            {
              title: "Ativos",
              active: false,
              disable: false,
              to: "/sistema/fornecedores/listagem/",
            },
            {
              title: "Reprovados",
              active: false,
              disable: false,
              to: "/sistema/fornecedores/reprovados/",
            },
            {
              title: "Cadastrar Novo",
              active: false,
              disable: false,
              to: "/sistema/fornecedores/novo/",
            },  
          ],
        },
        {
          title: "Inspirações",
          icon: "mdi-airballoon",
          active: false,
          disable: false,
          items: [
            {
              title: "Listagem",
              active: false,
              disable: false,
              to: "/sistema/inspiracoes/listagem/",
            },
            {
              title: "Cadastrar Novo",
              active: false,
              disable: false,
              to: "/sistema/inspiracoes/novo/",
            }, 
          ],
        },
        {
          title: "Convites",
          icon: "mdi-book",
          active: false,
          disable: false,
          items: [
            {
              title: "Listagem de Convites",
              active: false,
              disable: false,
              to: "/sistema/convites/listagem/",
            },
            {
              title: "Cadastrar Novo Convite",
              active: false,
              disable: false,
              to: "/sistema/convites/novo/",
            }, 
            {
              title: "Listagem de Temas",
              active: false,
              disable: false,
              to: "/sistema/temas/listagem/",
            },
            {
              title: "Cadastrar Novo Tema",
              active: false,
              disable: false,
              to: "/sistema/temas/novo/",
            }, 
             {
              title: "Listagem de Categorias",
              active: false,
              disable: false,
              to: "/sistema/categorias/listagem/",
            },
            {
              title: "Cadastrar Nova Categoria",
              active: false,
              disable: false,
              to: "/sistema/categorias/novo/",
            }, 
          ],
        },
        {
          title: "Usuários",
          icon: "mdi-account-group",
          active: false,
          disable: false,
          items: [
            {
              title: "Listagem",
              active: false,
              disable: false,
              to: "/sistema/usuarios/listagem/",
            }
          ],
        },
        {
          title: "Notificação",
          icon: "mdi-bell",
          active: false,
          disable: false,
          items: [
            {
              title: "Notificar",
              active: false,
              disable: false,
              to: "/sistema/notificar/",
            },
          ],
        },
      ],
    };
  },
  methods: {
    async logout() {
      const result = await database.signOut();

      if (result.message) {
        alert("Ops! " + result.message);
      } else {
        this.$router.replace({ path: "/sistema/login/" });
      }
    }
  },
};
</script>